import React from "react"
import {Link } from "gatsby"
import "../stylesheets/pager.css"
import {Button} from "../components/Button"

const Pager = ({pageContext}) =>{
    const {previousPagePath, nextPagePath} = pageContext;

    return(
        <div className="pagerdiv">
        {previousPagePath && (
            <span className="prevbtn"><Button to={previousPagePath}>Previous Page</Button></span>
        )}
        {nextPagePath && (
            <span className="nextbtn"><Button  to={nextPagePath}>Next Page</Button></span>
        )}
        </div>
    )
}
export default Pager