import { graphql } from "gatsby"
import "../stylesheets/general.css"
import React from "react"
import {
  Button,
  Card,
  Content,
  Hero,
  Layout,
  Subscribe,
  TopTacticsCard,
} from "../components"
import Pager from "../components/Pager"
import SEO from "../components/SEO"
import { TopTacticsList } from "../elements"

const TacticsTemplate = ({ data, pageContext }) => {
  const tactics = data.allWordpressPost.nodes

  return (
    <Layout>
      <SEO title={"Tactics"} description={"We have implemented transformational digital strategies for leading brands."} />
      <Hero
        title="The Future Is Here."
        videoUrl="https://itspixelperfect.s3.amazonaws.com/videos/About+IPP+-+Strategy+Loop.mp4"
        buttonTitle="Watch the Video"
        image={data.heroImage.fluid}
        hasBgVideo={true}
        description="We have implemented transformational digital strategies for leading brands."
      />
      <Content spacing>
        <Card
          name="Ideas Matter."
          margin="0 0 82px 0"
          title="Digital transformation is not a final destination. It is a process of change requiring a visionary mindset aligned with sound strategy and constant innovation."
          description="Here is where you’ll find the latest industry insights, news on innovation and exciting announcements.  From culturally relevant digital marketing tips to how we maximize clients’ return on investment. Your exclusive pass to the hub of ideas that matter."
        />
      </Content>
      <TopTacticsList>
        {tactics.map((tactic, index) => (
          <TopTacticsCard key={index} tactic={tactic} />
        ))}

        <Pager pageContext={pageContext}/>
      </TopTacticsList>
      <Subscribe />
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    heroImage: imageSharp(fluid: { originalName: { eq: "tacticshero.jpg" } }) {
      fluid(quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
    allWordpressPost (
        skip: $skip
        limit: $limit
    ) {
      nodes {
        id
        author {
          name
        }
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 430, maxHeight: 215, quality: 100) {
                srcSet
              }
            }
          }
        }
        categories {
          name
        }
      }
    }
  }
`
export default TacticsTemplate
